<template>
  <div class="main-menu">
    <div class="logoBox">
      <img src="@/assets/images/logo.png" alt />Alphant Pro
    </div>
    <div class="menu-srcoll">
      <vuescroll :ops="ops">
        <el-menu
          :default-openeds="openeds"
          :default-active="defaultActive"
          background-color="#304156"
          text-color="#fff"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
        >
          <template v-for="(item, index) in menuList">
            <el-submenu
              :index="item.name"
              :key="index"
              v-if="item.menuLists && item.menuLists.length > 0"
            >
              <template slot="title">
                <!-- <i :class="['el-icon-img', menuIcon(index)]"></i> -->
                <span>{{ item.name }}</span>
              </template>
              <template v-for="(i, indexs) in item.menuLists">
                <el-menu-item
                  :index="i.href"
                  v-if="i.href && i.href != 'null'"
                  :key="indexs"
                  @click="goUrl(i.href)"
                >{{ i.name }}</el-menu-item>
              </template>
            </el-submenu>
            <template v-else>
              <el-menu-item :index="item.href" :key="index" v-if="item.href" @click="goUrl(item.href)">
                <!-- <i class="el-icon-menu"></i> -->
                <span slot="title">{{ item.name }}</span>
              </el-menu-item>
            </template>
          </template>
        </el-menu>
      </vuescroll>
    </div>
  </div>
</template>

<script>
import vuescroll from "vuescroll";

export default {
  data() {
    return {
      menuListAll: [
        {
          id: 0,
          name: "用户管理",
          menuLists: [
            {
              id: 1,
              name: "用户列表",
              href: "/userManagement/userList"
            },
            {
              id: 1,
              name: "招募表单",
              href: "/userManagement/recruitJoin"
            }
          ]
        },
        {
          id: 1,
          name: "信息管理",
          menuLists: [
            {
              id: 1,
              name: "话题管理",
              href: "/infoManagement/topicList"
            },
            {
              id: 2,
              name: "banner管理",
              href: "/infoManagement/bannerList"
            },
            {
              id: 3,
              name: "文章管理",
              href: "/infoManagement/articleList"
            },
            {
              id: 4,
              name: "nft管理",
              href: "/infoManagement/nftList"
            },
            {
              id: 5,
              name: "白名单管理",
              href: "/infoManagement/whiteList"
            }
          ]
        },
        {
          id: 2,
          name: "任务管理",
          menuLists: [
            {
              id: 1,
              name: "任务列表",
              href: "/taskManagement/taskList"
            },
            {
              id: 1,
              name: "任务标签",
              href: "/taskManagement/taskLabels"
            }
          ]
        },
        {
          id: 3,
          name: "房间管理",
          menuLists: [
            {
              id: 1,
              name: "房间列表",
              href: "/roomManagement/roomList"
            }
          ]
        },
        {
          id: 4,
          name: "礼物管理",
          menuLists: [
            {
              id: 1,
              name: "礼物列表",
              href: "/giftManagement/giftList"
            }
          ]
        },
        {
          id: 20,
          name: "系统管理",
          menuLists: [
            {
              id: 1,
              name: "用户管理",
              href:'/systemManagement/accountList'
            },
            {
              id: 1,
              name: "角色管理",
              href:'/systemManagement/roleList'
            },
            {
              id: 1,
              name: "权限管理",
              href:'/systemManagement/permissionAssign'
            },
            {
              id: 1,
              name: "接口管理",
              href:'/systemManagement/apiList'
            },
          ]
        }
      ],
      menuList: [],
      ops: {
        bar: {
          background: "#ddd",
          keepShow: false,
          size: "3px",
          minSize: 0.2
        }
      },
      defaultActive: "orderList",
      openeds: []
    };
  },
  components: {
    vuescroll
  },
  created() {
    // this.menuList = this.menuListAll;
    this.routeActiveFunc(this.$route);
    this.getUserMenuFunc();
    this.openeds = this.menuList.map(x => x.name);
    this.getSubNavFunc(this.$route.path);
  },
  watch: {
    $route(val) {
      this.routeActiveFunc(val);
    }
  },
  methods: {
    handleOpen() {},
    handleClose() {},
    menuIcon(index) {
      let path = this.menuList[index].menuLists[0].href;
      let icon = "";
      return icon;
    },
    async getUserMenuFunc() {
      let res = await this.$http.menuListAll();
      if (res) {
        this.menuList = res.data;
        this.openeds = this.menuList.map(x => x.name);
        this.getSubNavFunc(this.$route.path);
      }
    },
    getSubNavFunc(url) {
      let pathSplit = url.split("/");
      if (pathSplit.length > 3) {
        pathSplit.splice(pathSplit.length - 1, 1);
        url = pathSplit.join("/");
      }
      let arr = [];
      this.menuList.forEach(x => {
        if (x.menuLists && x.menuLists.length > 0) {
          x.menuLists.forEach(y => {
            if (y.href == url) {
              arr = [
                { name: x.name, path: "" },
                { name: y.name, path: y.href }
              ];
            }
          });
        } else {
          if (x.href == url) {
            arr = [{ name: x.name, path: x.href }];
          }
        }
      });
      this.$emit("func", arr);
    },

    routeActiveFunc(val) {
      let pathSplit = val.path.split("/");
      if (pathSplit.length > 3) {
        pathSplit.splice(pathSplit.length - 1, 1);
        this.defaultActive = pathSplit.join("/");
      } else {
        this.defaultActive = val.path;
      }
    },
    goUrl(url) {
      if (this.$route.path !== url) {
        this.getSubNavFunc(url);
        this.$router.push({ path: url });
      }
    }
  }
};
</script>

<style lang="scss">
.main-menu {
  width: 220px;
  height: 100%;
  overflow: hidden;
  background: #304156;
  flex-shrink: 0;
  position: relative;
  .logoBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    color: #fff;
    background: #2c394b;
    img {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin-right: 12px;
    }
  }
}
.el-menu {
  border-right: 1px solid #304156;
}
.main-menu .menu-srcoll {
  height: calc(100% - 60px);
}
.el-submenu.is-active.is-opened .el-menu-item.is-active,
.el-menu > .el-menu-item.is-active {
  background: #209e91 !important;
  color: #fff;
}
.el-menu-item.is-active:hover {
  color: #fff !important;
}
.el-menu-item:hover {
  color: #209e91 !important;
}
.el-submenu.is-active.is-opened div,
.el-submenu.is-active.is-opened li {
  background-color: #1f2d3d !important;
}
</style>
